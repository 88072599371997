@mixin active-control
  background: #0077ff
  border-color: #0077ff

@mixin sidebar-button
  min-width: 80px
  appearance: none
  background-color: #ffffff
  border: 1px solid #cccfdb
  margin: 0
  border-radius: 10px
  padding: 6px 10px
