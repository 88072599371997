@import './Mixins'

.sidebar
  background: #343333
  position: fixed,
  top: 0
  right: 0
  bottom: 0,
  transition: width .5s
  overflow-y: auto
  &-sticky
    position: sticky
    top: 0px
    background: #343333
    padding: 20px
  &-container
    padding: 0px
  .close-sidebar
    position: absolute
    top: 5px
    right: 5px
    padding: 10px
    color: #fff
    z-index: 10
    font-size: 18px
    background: none
    border: none
    &:hover
      opacity: 0.8
  &-row
    &_item
      display: flex
      width: 100%
      flex-direction: column
      align-items: flex-start
      justify-content: flex-start
      gap: 10px
    &_keywords
      padding: 0px 20px
  & input[type='text']
    width: 100%
    display: flex
    padding: 12px
    border: 0
    border-radius: 2px
