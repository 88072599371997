@import './Mixins'

.radio-container
  display: flex
  flex-direction: row
  align-items: center
  width: 100%
  justify-content: space-between
  & h3
    margin: 0
    padding: 0
    font-weight: 300
    font-size: 16px
  & .radio-wrapper
    display: flex
    flex-direction: row
    align-items: center
    & .radio-control
      font-size: 32px
      padding: 5px
    & input[type='button']
      appearance: none
      background-color: #ffffff
      width: 30px
      height: 30px
      border: 1px solid #cccfdb
      margin: 0
      border-radius: 10px
      display: grid
      align-items: center
      justify-content: center
      transition: all 0.3s ease
      &.plus:before
        content: '+'
      &.minus:before
        content: '-'
      &.plus:before, &.minus:before
        font-size: 20px
        display: flex
        align-items: center
        justify-content: center
        width: 20px
        height: 20px
      &.selected
        @include active-control
